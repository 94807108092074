import React, { useRef, useState } from "react";
import FileUploader, { saveFiles } from "../components/FileUploader";
import SignatureBox, { saveSignature } from "../components/SignatureBox";
import SubmitButton from "../components/SubmitButton";
import sharedStyles from "./sharedStyles";

import { PDF_FILE_TYPE } from "../types";

const StatementVerificationForm = ({ loading, onSubmit }) => {
  const [bankStatments, setBankStatements] = useState([]);
  const signatureRef = useRef({});

  const handleSubmit = async () => {
    const files = await saveFiles(bankStatments);
    const signature = saveSignature(signatureRef);
    onSubmit([...files, signature]);
  };

  const isReady = bankStatments.length;

  return (
    <>
      <div style={sharedStyles.instructionsContainer}>
        <span style={sharedStyles.semiBold}>Instructions</span>
        <br />
        📁 Upload 2 months worth of bank statements for all connected accounts
        <br />
      </div>
      <div>
        ⚠️<span style={sharedStyles.semiBold}> Please note</span> — if you fail
        to follow the above instructions, you will be asked to complete this
        form again before we are able to remove the temporary hold on your
        account.
      </div>

      <div style={sharedStyles.divider} />

      <FileUploader
        setFiles={setBankStatements}
        files={bankStatments}
        fileType={PDF_FILE_TYPE}
        subtitle="Important! You must upload 2 months worth of bank statements for each connected account"
      />

      <div style={sharedStyles.footer}>
        <SignatureBox signatureRef={signatureRef} />
        <SubmitButton
          disabled={!isReady}
          loading={loading}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

export default StatementVerificationForm;
