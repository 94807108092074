import React, { useState } from "react";
import FileUploader, { saveFiles } from "../components/FileUploader";
import SubmitButton from "../components/SubmitButton";
import sharedStyles from "./sharedStyles";

import { MIXED_FILE_TYPE } from "../types";

const GenericUploadForm = ({ loading, onSubmit }) => {
  const [documents, setDocuments] = useState([]);

  const handleSubmit = async () => {
    const files = await saveFiles(documents);
    onSubmit([...files]);
  };

  const isReady = documents.length;

  return (
    <>
      <div style={sharedStyles.instructionsContainer}>
        <span style={sharedStyles.semiBold}>What you need to do</span>
        <br />
        📁 Please upload the requested documents. If you have questions, contact us via support!
        <br />
      </div>
      <div>
        ⚠️<span style={sharedStyles.semiBold}> File requirements</span> — We accept PNG and PDF files up to 20MB.
      </div>

      <div style={sharedStyles.divider} />

      <FileUploader
        setFiles={setDocuments}
        files={documents}
        fileType={MIXED_FILE_TYPE}
        subtitle="Click here to upload"
      />

      <div style={sharedStyles.footer}>
        <SubmitButton
          disabled={!isReady}
          loading={loading}
          onClick={handleSubmit}
          text="Submit"
        />
      </div>
    </>
  );
};

export default GenericUploadForm;
