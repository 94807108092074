import React, { useRef, useState } from "react";
import downloadIcon from "../assets/download.svg";
import {
  ALLOWED_MAX_FILE_BYTES,
  FILE_TOO_LARGE_ERROR,
  MIXED_FILE_TYPE,
  PDF_FILE_TYPE,
} from "../types";
import PdfPreview from "../components/PdfPreview";

export const saveFiles = (files) => {
  return Promise.all(files.map((file) => convertBase64(file)));
};

const FileUploader = ({ files, setFiles, subtitle, fileType }) => {
  const hiddenFileInput = useRef(null);
  const [errors, setErrors] = useState([]);

  const handleChange = (event) => {
    let errors = [];
    let filesToSet = [];
    const addedFiles = [...event.target.files] || [];

    for (let i = 0; i < addedFiles.length; i++) {
      if (addedFiles[i].size > ALLOWED_MAX_FILE_BYTES) {
        errors.push({ file: addedFiles[i], type: FILE_TOO_LARGE_ERROR });
      } else {
        filesToSet.push(addedFiles[i]);
      }
    }

    setErrors(errors);
    setFiles([...files, ...filesToSet]);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const isEmpty = files.length === 0;
  return (
    <>
      {!isEmpty && (
        <div
          onClick={() => {
            setFiles([]);
          }}
          style={styles.clearImage}
        >
          ❌
        </div>
      )}
      <div
        style={{
          ...styles.fileUpload,
          ...(fileType === PDF_FILE_TYPE || fileType === MIXED_FILE_TYPE
            ? files.length
              ? {
                  alignItems: "flex-start",
                  paddingTop: 40,
                }
              : { paddingTop: 24 }
            : {}),
        }}
        onClick={handleClick}
      >
        {isEmpty && (
          <img style={styles.downloadIcon} src={downloadIcon} alt="Upload" />
        )}

        <div style={styles.imagesRow}>
          {files.map((file, index) =>
            file.type === PDF_FILE_TYPE || fileType === MIXED_FILE_TYPE ? (
              <PdfPreview key={index} fileName={file.name} />
            ) : (
              <img
                key={index}
                style={styles.imagePreview}
                src={URL.createObjectURL(file)}
                alt="Preview"
              />
            )
          )}
        </div>

        {isEmpty && <div style={styles.imageTextSubtitle}>{subtitle}</div>}
      </div>
      <input
        type="file"
        multiple
        accept={fileType}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <div style={styles.errorContainer}>
        {errors.map((error) =>
          error.type === FILE_TOO_LARGE_ERROR ? (
            <p style={styles.errorText}>{error.file.name + " exceeds 20mb"}</p>
          ) : null
        )}
      </div>
    </>
  );
};

const styles = {
  fileUpload: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "lightGrey",
    borderStyle: "solid",
    borderRadius: 5,
    padding: 24,
    minHeight: 150,
    textalign: "center",
    flex: 1,
  },
  clearImage: {
    cursor: "pointer",
    marginBottom: -40,
    padding: 10,
    zIndex: 2,
  },
  imagePreview: {
    height: 75,
    width: 75,
    marginLeft: 10,
    marginRight: 10,
  },
  imageTextSubtitle: {
    textAlign: "center",
    fontSize: 13,
    marginTop: 20,
  },
  imagesRow: {
    flexDirection: "row",
  },
  downloadIcon: {
    width: 30,
  },
  errorContainer: {
    marginTop: 10,
  },
  errorText: {
    margin: 0,
    textAlign: "center",
    color: "#ff7c7c",
  },
};

export default FileUploader;

//helpers
const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
