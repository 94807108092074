import React, { useRef, useState } from "react";
import FileUploader, { saveFiles } from "../components/FileUploader";
import SignatureBox, { saveSignature } from "../components/SignatureBox";
import SubmitButton from "../components/SubmitButton";
import sharedStyles from "./sharedStyles";

import { IMAGE_FILE_TYPE, PDF_FILE_TYPE } from "../types";

const AccountVerificationForm = ({ loading, onSubmit }) => {
  const [selfieImage, setSelfieImages] = useState([]);
  const [bankStatments, setBankStatements] = useState([]);
  const signatureRef = useRef({});

  const handleSubmit = async () => {
    const files = await saveFiles([...selfieImage, ...bankStatments]);
    const signature = saveSignature(signatureRef);
    onSubmit([...files, signature]);
  };

  const isReady = selfieImage.length && bankStatments.length;

  return (
    <>
      <div style={sharedStyles.instructionsContainer}>
        <span style={sharedStyles.semiBold}>Instructions</span>
        <br />
        ✍️ Write today’s date on any standard piece of paper
        <br />
        📸 Take a selfie{" "}
        <span style={sharedStyles.semiBold}>
          with your ID, and the piece of paper in view
        </span>
        <br />
        📁 Upload 2 months worth of bank statements for all connected accounts
        <br />
      </div>
      <div>
        ⚠️<span style={sharedStyles.semiBold}> Please note</span> — if you fail
        to follow the above instructions, you will be asked to complete this
        form again before we are able to remove the temporary hold on your
        account.
      </div>

      <div style={sharedStyles.divider} />

      <FileUploader
        setFiles={setSelfieImages}
        files={selfieImage}
        fileType={IMAGE_FILE_TYPE}
        subtitle="Please ensure the selfie has your ID and the piece of paper clearly in view"
      />

      <div style={sharedStyles.spacer} />

      <FileUploader
        setFiles={setBankStatements}
        files={bankStatments}
        fileType={PDF_FILE_TYPE}
        subtitle="Important! You must upload 2 months worth of bank statements for each connected account"
      />

      <div style={sharedStyles.footer}>
        <SignatureBox signatureRef={signatureRef} />
        <SubmitButton
          disabled={!isReady}
          loading={loading}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

export default AccountVerificationForm;
