import React from "react";
import pdfIcon from "../assets/pdf.png";

const PdfPreview = ({ fileName }) => {
  return (
    <div style={styles.root}>
      <img style={styles.icon} src={pdfIcon} alt="PDF" />
      <span style={styles.text}>{fileName}</span>
    </div>
  );
};

const styles = {
  root: { display: "flex", alignItems: "center" },
  icon: { height: 30 },
  text: {
    fontSize: 12,
    maxWidth: 250,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export default PdfPreview;
