import React from "react";
import sharedStyles from "./sharedStyles";

const NotFound = () => {
  return (
    <>
      <div style={sharedStyles.instructionsContainer}>
        <div>
          ⚠️<span style={sharedStyles.semiBold}> Error 404</span> page not
          found.
        </div>
      </div>
      <div style={sharedStyles.divider} />
    </>
  );
};

export default NotFound;
