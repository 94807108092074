import logo from "./assets/logo.svg";

import "./App.css";
import React, { useState } from "react";
import ResultScreen from "./components/ResultScreen";
import {
  SUCCESS_RESULT,
  INVALID_TOKEN_ERROR_RESULT,
  GENERAL_ERROR_RESULT,
  URL_ACTION,
  ACCOUNT_VERIFICATION_FORM,
  MANUAL_KYC_FORM,
  STATEMENT_VERIFICATION_FORM,
  GENERIC_UPLOAD_FORM,
} from "./types";

import useTokenVerification from "./hooks/useTokenVerification";
import AccountVerificationForm from "./forms/AccountVerificationForm";
import ManualKycForm from "./forms/ManualKycForm";
import StatementVerificationForm from "./forms/StatementVerificationForm";
import NotFound from "./forms/NotFound";
import GenericUploadForm from "./forms/GenericUploadForm";

function App() {
  const [loading, setLoading] = useState(false);
  const [resultType, setResultType] = useState(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const formType = params.get("form_type");
  const token = params.get("token");
  const conversationId = params.get("conversationId");
  const actionType = params.get("action");

  const handleSuccess = () => {
    window.INTERCOM_MESSENGER_SHEET_LIBRARY.submitSheet({
      status: "SUCCESS",
    });
    setResultType(SUCCESS_RESULT);
  };

  const handleError = (errorType = GENERAL_ERROR_RESULT) => {
    window.INTERCOM_MESSENGER_SHEET_LIBRARY.submitSheet({
      status: "FAILED",
    });
    setResultType(errorType);
  };

  const handleReturn = () => {
    window.location.replace(process.env.REACT_APP_APP_URI);
  };

  const handleSubmit = async (files) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/intercom/forms/upload`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        formType: formType,
        conversationId: conversationId,
        action: actionType,
        files: files,
      }),
    })
      .then((response) => {
        if (response.ok) {
          handleSuccess();
        } else {
          handleError();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        handleError();
        setLoading(false);
      });
  };

  useTokenVerification({
    token,
    conversationId,
    onError: () => handleError(INVALID_TOKEN_ERROR_RESULT),
  });

  if (resultType) {
    return (
      <div className="App" style={{ ...styles.root, ...styles.messageRoot }}>
        <ResultScreen
          type={resultType}
          onReturn={actionType === URL_ACTION ? handleReturn : null}
        />
      </div>
    );
  }

  if (formType === ACCOUNT_VERIFICATION_FORM) {
    return (
      <div className="App" style={styles.root}>
        <img style={styles.logo} src={logo} alt="Donut Logo" />
        <div style={styles.container}>
          <AccountVerificationForm loading={loading} onSubmit={handleSubmit} />
        </div>
      </div>
    );
  }

  if (formType === MANUAL_KYC_FORM) {
    return (
      <div className="App" style={styles.root}>
        <img style={styles.logo} src={logo} alt="Donut Logo" />
        <div style={styles.container}>
          <ManualKycForm loading={loading} onSubmit={handleSubmit} />
        </div>
      </div>
    );
  }

  if (formType === STATEMENT_VERIFICATION_FORM) {
    return (
      <div className="App" style={styles.root}>
        <img style={styles.logo} src={logo} alt="Donut Logo" />
        <div style={styles.container}>
          <StatementVerificationForm
            loading={loading}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    );
  }
  if (formType === GENERIC_UPLOAD_FORM) {
    return (
      <div className="App" style={styles.root}>
        <img style={styles.logo} src={logo} alt="Donut Logo" />
        <div style={styles.container}>
          <GenericUploadForm loading={loading} onSubmit={handleSubmit} />
        </div>
      </div>
    );
  }

  return (
    <div className="App" style={styles.root}>
      <img style={styles.logo} src={logo} alt="Donut Logo" />
      <div style={styles.container}>
        <NotFound />
      </div>
    </div>
  );
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 20,
    marginBottom: 60,
  },
  container: {
    maxWidth: 550,
    margin: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  messageRoot: {
    height: "100vh",
    animation: "fadeIn 2s",
    paddingTop: 0,
    marginBottom: 0,
  },
  logo: {
    width: "auto",
    height: 40,
  },
};

export default App;
