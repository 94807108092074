import React from "react";
import SignatureCanvas from "react-signature-canvas";

export const saveSignature = (signatureRef) => {
  return signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
};

const SignatureBox = ({ signatureRef }) => {
  const clearSignature = () => signatureRef.current.clear();

  return (
    <>
      <div style={styles.signatureSectionHeader}>
        Signature<span style={styles.asterisk}>*</span>
        <span onClick={clearSignature} style={styles.signatureHeaderClear}>
          Clear
        </span>
      </div>
      <div style={styles.signatureContainer}>
        <SignatureCanvas
          penColor="black"
          ref={signatureRef}
          canvasProps={styles.canvas}
        />
      </div>
    </>
  );
};

const styles = {
  signatureSectionHeader: {
    flexDirection: "row",
  },
  signatureHeaderClear: {
    cursor: "pointer",
    fontWeight: 500,
    float: "right",
  },
  asterisk: {
    color: "red",
  },
  signatureContainer: {
    cursor: "pointer",
    width: 300,
    borderColor: "lightGrey",
    borderStyle: "solid",
    borderRadius: 5,
    borderWidth: 1,
    padding: 0,
  },
  canvas: {
    width: 300,
    height: 120,
    padding: 0,
    margin: 0,
  },
};
export default SignatureBox;
