// ResultScreen Types
export const INVALID_TOKEN_ERROR_RESULT = "INVALID_TOKEN_ERROR_RESULT";
export const GENERAL_ERROR_RESULT = "GENERAL_ERROR_RESULT";
export const SUCCESS_RESULT = "SUCCESS_RESULT";

// Action Types
export const URL_ACTION = "url";
export const SHEET_ACTION = "sheet";

// Form types
export const MANUAL_KYC_FORM = "MANUAL_KYC";
export const ACCOUNT_VERIFICATION_FORM = "ACCOUNT_VERIFICATION";
export const STATEMENT_VERIFICATION_FORM = "STATEMENT_VERIFICATION";
export const GENERIC_UPLOAD_FORM = "GENERIC_UPLOAD";

// File Types
export const IMAGE_FILE_TYPE = "image/*";
export const PDF_FILE_TYPE = "application/pdf";
export const MIXED_FILE_TYPE = "image/*,application/pdf";

// SIZE
export const ALLOWED_MAX_FILE_BYTES = 1048576 * 20;
export const FILE_TOO_LARGE_ERROR = "FILE_TOO_LARGE_ERROR";
