const sharedStyles = {
  instructionsContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  semiBold: {
    fontWeight: 600,
  },
  semiBoldItalic: {
    fontWeight: 600,
    fontStyle: "italic",
  },
  divider: {
    marginTop: 20,
    marginBottom: 50,
    height: 1,
    backgroundColor: "lightGrey",
  },
  footer: {
    marginTop: 50,
  },
  asterisk: {
    color: "red",
  },
  imagesRow: {
    flexDirection: "row",
  },
  numberStepContainer: {
    textAlign: "center",
    marginBottom: 50,
  },
  numberStepText: {
    color: "#ff7c7c",
  },
  spacer: {
    height: 40,
  },
};

export default sharedStyles;
