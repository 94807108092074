import React, { useRef, useState } from "react";
import FileUploader, { saveFiles } from "../components/FileUploader";
import SignatureBox, { saveSignature } from "../components/SignatureBox";
import SubmitButton from "../components/SubmitButton";
import sharedStyles from "./sharedStyles";

import { IMAGE_FILE_TYPE } from "../types";

const ManualKycForm = ({ loading, onSubmit }) => {
  const [frontImage, setFrontImages] = useState([]);
  const [backImage, setBackImages] = useState([]);
  const signatureRef = useRef({});

  const handleSubmit = async () => {
    const files = await saveFiles([...frontImage, ...backImage]);
    const signature = saveSignature(signatureRef);
    onSubmit([...files, signature]);
  };

  const isReady = frontImage.length && backImage.length;

  return (
    <>
      <div style={sharedStyles.instructionsContainer}>
        <div>
          ⚠️<span style={sharedStyles.semiBold}> Please note</span> — if you
          fail to follow the below instructions, you will be asked to complete
          this form again before you are able to open an account.
        </div>
      </div>

      <div style={sharedStyles.divider} />

      <div style={sharedStyles.numberStepContainer}>
        <span style={sharedStyles.numberStepText}>1)</span> Upload a photograph
        of the <span style={sharedStyles.semiBold}>front and back</span> of your
        ID card <span style={sharedStyles.semiBoldItalic}>OR</span> the main
        page of your passport <span style={sharedStyles.asterisk}>*</span>
      </div>

      <div style={sharedStyles.divider} />

      <FileUploader
        setFiles={setFrontImages}
        files={frontImage}
        fileType={IMAGE_FILE_TYPE}
        subtitle=" Please ensure the front and back of your ID are clear and visible"
      />
      <div style={sharedStyles.spacer} />

      <div style={sharedStyles.divider} />

      <div style={sharedStyles.numberStepContainer}>
        <span style={sharedStyles.numberStepText}>2)</span> Upload a selfie with
        the <span style={sharedStyles.semiBold}>same</span> document{" "}
        <span style={sharedStyles.semiBoldItalic}>AND</span> a piece of paper
        with today's date in view <span style={sharedStyles.asterisk}>*</span>
      </div>

      <div style={sharedStyles.divider} />

      <FileUploader
        setFiles={setBackImages}
        files={backImage}
        fileType={IMAGE_FILE_TYPE}
        subtitle="Please ensure the selfie has your ID and the piece of paper clearly in view"
      />

      <div style={sharedStyles.footer}>
        <SignatureBox signatureRef={signatureRef} />
        <SubmitButton
          disabled={!isReady}
          loading={loading}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

export default ManualKycForm;
