import React from "react";
import loader from "../assets/loader.gif";

const SubmitButton = ({ loading, disabled, onClick, style, text }) => {
  return (
    <div
      onClick={onClick}
      style={
        !disabled
          ? { ...styles.submitButton, ...style }
          : { ...styles.submitButtonDisabled, ...style }
      }
    >
      {!loading && <>{text ? text : "Submit for Review"}</>}
      {loading && <img src={loader} style={styles.loader} alt="Loading" />}
    </div>
  );
};

const styles = {
  submitButton: {
    cursor: "pointer",
    marginTop: 60,
    color: "white",
    backgroundColor: "#ff7c7c",
    borderRadius: 5,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonDisabled: {
    marginTop: 60,
    color: "white",
    backgroundColor: "#ffbdbd",
    borderRadius: 5,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    height: 20,
    width: 20,
  },
};

export default SubmitButton;
