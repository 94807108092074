import React from "react";

import checkIcon from "../assets/check.svg";
import errorIcon from "../assets/error.svg";
import {
  SUCCESS_RESULT,
  INVALID_TOKEN_ERROR_RESULT,
  GENERAL_ERROR_RESULT,
} from "../types";
import SubmitButton from "./SubmitButton";

const getParams = (type) => {
  const errorStlye = { ...styles.title, ...styles.errorTitle };

  switch (type) {
    case SUCCESS_RESULT:
      return {
        alt: "Success",
        icon: checkIcon,
        title: "Thank you",
        titleStyle: styles.title,
        body: "We will review the information you have submitted ASAP, usually within 48hrs, and let you know once your account has been verified 🍩",
      };
    case INVALID_TOKEN_ERROR_RESULT:
      return {
        alt: "Error",
        icon: errorIcon,
        title: "Invalid or Expired Link!",
        titleStyle: errorStlye,
        body: "We are sorry for the inconvenience. Please reach out to our support team to manually verify your account.",
      };
    case GENERAL_ERROR_RESULT:
      return {
        alt: "Error",
        icon: errorIcon,
        title: "Oops, something went wrong.",
        titleStyle: errorStlye,
        body: "There has been a technical issue. Please reach out to our support team to manually verify your account.",
      };

    default:
      return null;
  }
};

const ResultScreen = ({ type, onReturn }) => {
  const params = getParams(type);

  if (!params) {
    return null;
  }

  return (
    <div style={styles.messageContainer}>
      <img style={styles.messageImg} src={params.icon} alt={params.alt} />
      <h3 style={params.titleStyle}>{params.title}</h3>
      <p style={styles.messageBody}>{params.body}</p>
      {!!onReturn && (
        <SubmitButton text="Back to app" onClick={onReturn} style={styles.submitButton} />
      )}
    </div>
  );
};

const styles = {
  submitButton: {
    width: "80%",
  },
  messageContainer: {
    maxWidth: 360,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  messageImg: {
    width: "auto",
    height: 55,
  },
  title: {
    fontSize: 16,
    lineHeight: 1.5,
    paddingTop: 22,
    margin: 0,
    fontWeight: 500,
    textAlign: "center",
  },
  errorTitle: {
    color: "#D9695C",
  },
  messageBody: {
    textAlign: "center",
    marginTop: 10,
    padding: "0 15px",
  },
};

export default ResultScreen;
