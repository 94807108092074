import { useEffect } from "react";

const useTokenVerification = ({ token, conversationId, onError }) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;
    fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/intercom/forms/verify?token=${encodeURIComponent(
        token
      )}&conversationId=${conversationId}`
    )
      .then((response) => {
        if (!response.ok) {
          onError();
        }
      })
      .catch(() => {
        onError();
      });
  }, []);
};

export default useTokenVerification;
